<div id="vla-parent-wrapper" [ngStyle]="{'min-height': adjustedHeight}">
  <div class="row">
    <div class="col-sm-2" *ngIf="vlaEnabled()">
      <ul class="innder-side-nav">
        <li [class.active]="router.url === '/vla/automations'">
          <i class="fa-regular fa-wand-sparkles"></i> <a routerLink="/vla/automations">Automations</a>
        </li>
        <li [class.active]="router.url.includes('/vla/allconversations')">
          <i class="far fa-people-arrows"></i><a routerLink="/vla/allconversations/active">Conversations</a>
          <ul class="nested-menu" [ngStyle]="{'display': router.url.includes('/vla/allconversations') ? 'block' : 'none'}">
            <li [class.active]="router.url === '/vla/allconversations/all'">
              <a routerLink="/vla/allconversations/all">All</a>
            </li>
            <li [class.active]="router.url === '/vla/allconversations/active-handoff'">
              <a routerLink="/vla/allconversations/active-handoff">Active Handoff</a>
            </li>
            <li [class.active]="router.url === '/vla/allconversations/email'">
              <a routerLink="/vla/allconversations/email">Email</a>
            </li>
            <li [class.active]="router.url === '/vla/allconversations/phone'">
              <a routerLink="/vla/allconversations/phone">Phone</a>
            </li>
            <li [class.active]="router.url === '/vla/allconversations/active'">
              <a routerLink="/vla/allconversations/active">Active</a>
            </li>
          </ul>
        </li>
        <!-- <li [class.active]="router.url === '/vla/dashboard'">
          <i class="far fa-chart-pie"></i> <a routerLink="/vla/dashboard">Dashboard</a>
        </li> -->
        <li [class.active]="router.url === '/vla/knowledgebase'">
          <i class="fa-regular fa-robot-astromech"></i> <a routerLink="/vla/knowledgebase">Knowledge Base</a>
        </li>
        <li [class.active]="router.url === '/settings/vla'" *ngIf="userData.claims.includes('vla') && userData.voiceAssistantId">
          <i class="fa-regular fa-brain-circuit"></i> <a routerLink="/settings/vla">Settings</a>
        </li>
      </ul>
      <button *ngIf="isAdmin && !isEmailAndTextVlaEnabled" (click)="enableVLA('email-text')" class="button button-dark margin-top-12 full-width">Enable Email and Text VLA</button>
      <button *ngIf="isAdmin && !isVoiceVlaEnabled" (click)="enableVLA('voice')" class="button button-dark margin-top-12 full-width">Enable Voice VLA</button>
    </div>
    <div class="col-sm-10">
      <div *ngIf="vlaEnabled()">
        <router-outlet></router-outlet>
      </div>
    </div>
    <div *ngIf="!vlaEnabled()" class="enable-vla-button-wrap">
      <p class="text-center"><img src="/assets/virtual-leasing-assistant-placeholder.png" /></p>
      <h3 class="text-center">
        <a target="_blank" [href]="vlaHref">Click Here</a> to enable the Nurture Boss Virtual Leasing Assistant!
      </h3>
      <div >
        <button *ngIf="isAdmin" (click)="enableVLA('email-text')" class="button button-dark margin-top-12 margin-right-12">Enable Email and Text VLA</button>
        <button *ngIf="isAdmin" (click)="enableVLA('voice')" class="button button-dark margin-top-12 margin-left-12">Enable Voice VLA</button>
      </div>
    </div>
  </div>
</div>
  