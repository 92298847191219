// Angular:
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// Services:
import {
  AuthenticationService,
  ToastService,
  LoaderService,
  SettingsService,
  SettingType,
  VoiceVLAService,
} from '@app/_services';

// Types
import { User } from '@nurtureboss/common/dist/types/users';
import { PropertySettings } from '@nurtureboss/common/dist/types/settings';

enum TransferCallDestinationType {
  EmergencyMaintenance = 'emergencyMaintenance',
  Representative = 'representative',
}

interface TransferCallDestination {
  number: string,
  message: string,
  enabled: boolean,
  type: TransferCallDestinationType,
}

@Component({
  selector: 'app-integrations',
  templateUrl: './vla.component.html',
  styleUrls: ['./vla.component.less']
})
export class VLASettingsComponent implements OnInit {
  user: Partial<User> = {};
  propertySettings: PropertySettings = {
    emergencyMaintenancePhoneNumber: '',
  }
  voiceAssistant: any = {};
  savingSettings = false;
  hasVoiceAssistant = false;
  emergencyMaintenanceDestination: TransferCallDestination = {
    type: TransferCallDestinationType.EmergencyMaintenance,
    enabled: false,
    number: '',
    message: 'I am forwarding your call to emergency maintenance. Please stay on the line.',
  };
  representativeDestination: TransferCallDestination = {
    type: TransferCallDestinationType.Representative,
    enabled: false,
    number: '',
    message: 'I am forwarding your call to a representative. Please stay on the line.',
  }

  constructor(
    private authService: AuthenticationService,
    private voiceVLAService: VoiceVLAService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private settingsService: SettingsService,
  ) {
    // no-op
  }
  
  ngOnInit() {
    this.loaderService.triggerLoader();
    this.loadData();
  }

  async loadData() {
    this.user = this.authService.currentUserValue.user;
    if (this.user.voiceAssistantId) {
      this.loadVoiceAssistant(this.user.voiceAssistantId);
    }
    this.propertySettings = (await this.settingsService.getSettingsByType(SettingType.Property).toPromise()).result;
    this.emergencyMaintenanceDestination.number = this.propertySettings.emergencyMaintenancePhoneNumber;
  }

  async loadVoiceAssistant(voiceAssistantId) {
    try {
      const res = await this.voiceVLAService.getVoiceAssistant(voiceAssistantId).toPromise();
      if (!res.result.id) {
        return;
      }

      this.hasVoiceAssistant = true;
      this.voiceAssistant = res.result;

      if (res.result.model?.tools?.length) {
        const transferCallTool = res.result.model.tools.find(tool => tool.type === 'transferCall');
        if (transferCallTool?.destinations?.length) {
          transferCallTool.destinations.forEach(destination => {
            // There is no name field on destinations so have to search descriptions for keywords
            // Descriptions are hardcoded and not editable by users so they are consistent
            if (destination.description.includes('emergency maintenance')) {
              this.emergencyMaintenanceDestination.message = destination.message;
              this.emergencyMaintenanceDestination.enabled = true;
            }
            if (destination.description.includes('representative')) {
              this.representativeDestination.number = destination.number.slice(2); // Slice to remove +1
              this.representativeDestination.message = destination.message;
              this.representativeDestination.enabled = true;
            }
          })
        }
      }  
    } catch (err) {
      this.toastService.showError('Error retrieving voice VLA settings.');
    }
    this.loaderService.stopLoader();
  }

  async updateCallTransferDestinations() {
    if (
      this.emergencyMaintenanceDestination.enabled
      && this.representativeDestination.enabled
      && this.emergencyMaintenanceDestination.number == this.representativeDestination.number // == operator since could be string or number
    ) {
      this.toastService.showError('Cannot update: Call Transfer Numbers Must Be Unique');
      return;
    }

    if (
      (this.emergencyMaintenanceDestination.enabled && !this.emergencyMaintenanceDestination.message)
      || (
        this.representativeDestination.enabled 
        && (!this.representativeDestination.message || !this.representativeDestination.number)
      )
    ) {
      this.toastService.showError('Cannot update: Missing Fields');
      return;
    }

    this.loaderService.triggerLoader();

    try {
      const payload = [{
        ...this.emergencyMaintenanceDestination,
        number: '+1' + this.emergencyMaintenanceDestination.number,
      }, {
        ...this.representativeDestination,
        number: '+1' + this.representativeDestination.number,
      }];

      await this.voiceVLAService.updateCallTransferDestinations(this.voiceAssistant.id, payload).toPromise();
      this.toastService.showSuccess('Successfully Updated Call Transers!');
    } catch (err) {
      this.toastService.showError('There was an error updating you call transfers.');
    }
    this.loaderService.stopLoader();
  }
}