import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SettingsService, SettingType } from '@app/_services/settings.service';
import { ToastService, UsersService } from '@app/_services';
import { IBotSettings, BotSmsSetting } from '@nurtureboss/common/dist/types/settings';
import { IntegrationService } from '@app/_services/integration.service';

@Component({
  selector: 'app-bot',
  templateUrl: './bot.component.html',
  styleUrls: ['./bot.component.less']
})
export class BotComponent implements OnInit {

  @Input() subPage: string;

  smsForm = new FormGroup({
    smsSetting: new FormControl(BotSmsSetting.Off as string),
  });
  saving = false;
  localBotFAQContent = {
    feesAndDeposits: {
      content: '',
      details: [
        {
          name: 'Security Deposit',
          content: '',
          placeholder: '$100'
        },
        {
          name: 'Non-Refundable Deposits',
          content: '',
          placeholder: '$250 Move Out Fee & a $200 Security Deposit'
        },
        {
          name: 'Application Fee',
          content: '',
          placeholder: '$45'
        },
        {
          name: 'Pet Fee',
          content: '',
          placeholder: '$250 Non-Refundable Deposit and $25 per Mont Pet Rent'
        },
        {
          name: 'Parking Fee',
          content: '',
          placeholder: '$150 per Month Garage Fee (Optional)'
        },
        {
          name: 'Amenities Fee',
          content: '',
          placeholder: '$75 Clubhouse Rental'
        },
        {
          name: 'Late Rent Fee',
          content: '',
          placeholder: '$15 per Day After the 5th of the Month'
        },
        {
          name: 'Move In Costs',
          content: '',
          placeholder: 'First months rent, security deposit, and pet deposit are due at move in.'
        },
      ],
    },
    petPolicy: {
      content: '',
      details: [
        {
          name: 'Pet Deposit',
          content: '',
          placeholder: '$250 Non-Refundable'
        },
        {
          name: 'Pet Rent',
          content: '',
          placeholder: '$25 per Month'
        },
        {
          name: 'Pet Amenities',
          content: '',
          placeholder: 'Dog wash station, bark park, etc...'
        },
        {
          name: 'Pet Breed Restrictions',
          content: '',
          placeholder: 'We do not allow Pitbulls and...'
        },
      ],
    },
    amenities: {
      content: '',
      details: [
        {
          name: 'Community Amenities',
          content: '',
          placeholder: 'At our property we have outdoor BBQs, a salwater pool...'
        },
        {
          name: 'Apartment Amenities',
          content: '',
          placeholder: 'Washer and dryer, smart locks...'
        },
        {
          name: 'Reserved Amenities',
          content: '',
          placeholder: 'You can reserve the Clubhouse...'
        },
      ],
    },
    localInfo: {
      content: '',
      details: [
        {
          name: 'Local School Districts',
          content: '',
          placeholder: 'ACME Elementary School, Jones Middle Schoole, Reacher High School...'
        },
        {
          name: 'Google Maps URL',
          content: '',
          placeholder: 'https://maps.google.com...'
        },
        {
          name: 'Shopping',
          content: '',
          placeholder: 'Grocery stores, pharmacies, malls, etc...'
        },
        {
          name: 'Entertainment',
          content: '',
          placeholder: 'Theaters, sports stadiums, concert halls, etc...'
        },
      ],
    },
    contactInfo: {
      content: '',
      details: [
        {
          name: 'Phone Number',
          content: '',
          placeholder: '(602) 011-3345'
        },
        {
          name: 'Email Address',
          content: '',
          placeholder: 'leasing@myapartments.com'
        },
        {
          name: 'Address',
          content: '',
          placeholder: '1234 South Main Street...'
        },
        {
          name: 'Website',
          content: '',
          placeholder: 'https://myapartments.com...'
        },
        {
          name: 'Hours',
          content: '',
          placeholder: '9am to 5pm Sunday through Monday'
        },
      ],
    },
    concessions: {
      content: '',
      details: [],
    },
    photos: {
      content: '',
      details: [],
    },
    pricingAndAvailability: {
      content: '',
      details: [
        {
          name: 'Floorplans URL',
          content: '',
          Placeholder: 'https://myapartments.com/floorplans...'
        },
      ],
    },
    includedUtilities: {
      content: '',
      details: [
        {
          name: 'WiFi',
          content: '',
          placeholder: 'Internet is included in rent and provided by Cox...'
        },
        {
          name: 'Electric',
          content: '',
          placeholder: 'Electric is included in rent and provided by SRP...'
        },
        {
          name: 'Water',
          content: '',
          placeholder: 'Electric is included in rent and provided by the city...'
        },
        {
          name: 'Trash',
          content: '',
          placeholder: 'Valet trash is included in rent and provided by the property...'
        },
      ],
    },
    onlineApplicationUrl: {
      content: '',
      details: [],
    },
    scheduleTourUrl: {
      content: '',
      details: [],
    },
    leasingCriteria: {
      content: '',
      details: [
        {
          name: 'Fair Housing',
          content: '',
          placeholder: 'The community does not discriminate...'
        },
        {
          name: 'Income Verification',
          content: '',
          placeholder: 'Residents must show proof of income that is 3x...'
        },
        {
          name: 'Criminal History',
          content: '',
          placeholder: 'Criminal background screening...'
        },
        {
          name: 'Credit Checks',
          content: '',
          placeholder: 'Credit screening...'
        },
        {
          name: 'Past Evictions',
          content: '',
          placeholder: 'Applicants with eviction records in the last 5 years...'
        },
        {
          name: 'Occupancy Standards',
          content: '',
          placeholder: 'No more that two persons per bedroom...'
        },
        {
          name: 'Renters Insurance',
          content: '',
          placeholder: 'Must carry a minimum of $100,000...'
        },
        {
          name: 'Guarantors',
          content: '',
          placeholder: 'Guarantors may be used...'
        },
        {
          name: 'Section 8/Affordable Housing/Housing Vouchers',
          content: '',
          placeholder: 'Please contact the leasing office to learn...'
        },
      ],
    },
    parking: {
      content: '',
      details: [
        {
          name: 'Covered Parking',
          content: '',
          placeholder: 'All resident parking is covered...'
        },
        {
          name: 'Visitor Parking',
          content: '',
          placeholder: 'Uncovered parking spots are visitor...'
        },
        {
          name: 'Garages',
          content: '',
          placeholder: 'A garage can be rented for an additional...'
        },
      ],
    },
  };

  constructor(
    private settingsService: SettingsService,
    private toastService: ToastService,
    private usersService: UsersService,
  ) { }

  ngOnInit(): void {
    this.settingsService.getSettingsByType<IBotSettings>(SettingType.Bot).subscribe((response) => {
      const botFaqSettings = response.result.faq || {};
      for (const faq in botFaqSettings) {
        if (botFaqSettings?.[faq].content) {
          this.localBotFAQContent[faq].content = botFaqSettings[faq].content;
        }
        if (botFaqSettings?.[faq].details) {
          for (const detail of botFaqSettings[faq].details) {
            
            // TODO: I'm sure there is a more efficient way to do this.
            for (let i = 0; i < this.localBotFAQContent[faq].details.length; i++) {
              const localDetail = this.localBotFAQContent[faq].details[i];
              if (detail.name === localDetail.name) {
                this.localBotFAQContent[faq].details[i] = detail;
              }
            }
          }
        }
      }

      if (response.result.onlineApplicationUrl) {
        this.localBotFAQContent.onlineApplicationUrl.content = response.result.onlineApplicationUrl;
      }

      if (response.result.scheduleTourUrl) {
        this.localBotFAQContent.scheduleTourUrl.content = response.result.scheduleTourUrl;
      }

      if (response.result.smsSettings) {
        this.smsForm.controls.smsSetting.setValue(response.result.smsSettings, {
          onlySelf: true,
          emitEvent: true,
        });
      }
    });
  }

  saveBotSMS(event: Event) {
    event.preventDefault();

    this.saving = true;
    this.settingsService.saveSettingsByType(SettingType.Bot, {
      smsSettings: this.smsForm.controls.smsSetting.value,
    }).subscribe((response) => {
      this.saving = false;
      this.toastService.showSuccess('Bot SMS settings saved');
    }, (error) => {
      this.saving = false;
      this.toastService.showError('Something went wrong saving your settings!');
    });
  }

  saveBotFaq(event: Event) {
    event.preventDefault();

    const botSettingsFaq: any = {};
    for (const control in this.localBotFAQContent) {
      // TODO: extend this to a list or something when we add more properties
      if (control === 'onlineApplicationUrl') {
        continue;
      }

      if (control === 'scheduleTourUrl') {
        continue;
      }

      if (this.localBotFAQContent[control].content) {
        botSettingsFaq[control] = this.localBotFAQContent[control];
      
      // Need to see if details were added even though main content was not.
      } else {
        let hasDetails = false;
        for (const detail of this.localBotFAQContent[control].details) {
          if (detail.content) {
            hasDetails = true;
          }
        }
        if (hasDetails) {
          botSettingsFaq[control] = this.localBotFAQContent[control];
        }
      }
    }

    this.saving = true;
    this.settingsService.saveSettingsByType(SettingType.Bot, {
      onlineApplicationUrl: this.localBotFAQContent.onlineApplicationUrl.content,
      scheduleTourUrl: this.localBotFAQContent.scheduleTourUrl.content,
      faq: {...botSettingsFaq},
    }).subscribe((_response) => {

      // Update concession in global defaults
      this.usersService.setTemplateSelectedDefaults({
        defaultOptions: {
          defaults: true,
        }, 
        globalDefaults: {
          concession: botSettingsFaq.concessions.content,
        }
      }).subscribe();

      this.saving = false;
      this.toastService.showSuccess('Bot FAQ settings saved');
    }, (_error) => {
      this.saving = false;
      this.toastService.showError('Something went wrong saving your settings!');
    });
  }
}
