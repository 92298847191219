<div id="vla-conversation" [ngStyle]="{'height': adjustedHeight}">
  <div class="page-title">
    <h2 (click)="goBack()" class="link no-underline pull-left"><i class="fa-regular fa-chevron-left"></i> Go Back</h2>
    <h2 class="pull-right">Conversation with {{contactName}}</h2>
  </div>
  
  <!-- Text Disabled or Unresolved Thread Warning Message -->
  <div *ngIf="thread.textingAIDisabled || thread.passedToOnsiteTeam" class="messages-wrapper">
    <div class="alert alert-warning">
      <span>
        {{thread.textingAIDisabled ? 'AI is disabled for texting on this contact. If this contact replies, the AI will not respond. If you do not want to monitor this conversation, and want the AI to respond, click the “Enable AI” button. ': ''}}
        {{thread.passedToOnsiteTeam ? 'This conversation is in an unresolved state. This means the AI needs assistance to answer a question or address the concern of a prospect or resident. You can either reply directly using the text field and Send button below or you can reach out to the prospect or resident directly. Once you have resolved this issue, please mark this thread as "Resolved" so the AI knows it can continue to communicate with this prospect or resident. ' : ''}}
      </span>
    </div>
  </div>

  <!-- EMAIL & TEXT THREAD UI -->
  <div class="chat-wrapper" *ngIf="!thread.callId">
    <div class="message-action-wrap">
      
      <!-- Thread Resolution -->
      <ng-container>
        <div *ngIf="!thread.passedToOnsiteTeam" class="button button-small pull-right button-danger" (click)="unresolveThread(true)">Mark Thread As Unresolved</div>
        <div *ngIf="thread.passedToOnsiteTeam" class="button button-small pull-right button-dark" (click)="unresolveThread(false)">Mark Thread As Resolved</div>
      </ng-container>

      <!-- Mark Lead as Lost -->
      <div *ngIf="showMarkAsLostButton" id="mark-as-lost-button" class="button button-small pull-right button-basic" (click)="markContactAsLost()">Mark as Lost{{thread.passedToOnsiteTeam ? ' and Resolve' : ''}}</div>
      
      <!-- Disable AI for Text Threads -->
      <ng-container *ngIf="thread.isTextMessageThread">
        <div *ngIf="!thread.textingAIDisabled" class="disable-ai-button button button-small pull-right button-danger" (click)="textDisableAI(true)">Disable AI</div>
        <div *ngIf="thread.textingAIDisabled" class="disable-ai-button button button-small pull-right button-dark" (click)="textDisableAI(false)">Enable AI</div>
      </ng-container>
    </div>
    
    <div id="chat-window" [ngClass]="{'email-warning-showing': (thread.passedToOnsiteTeam || thread.textingAIDisabled)}">
      <div class="chat-bubble-wrap" *ngFor="let message of messages; let i = index">
        <div
          class="chat-text"
          [ngClass]="{'from-them': message.originator === 'user', 'from-me': message.originator === 'assistant'}"
          [innerHTML]="message.HTMLConversion"
        ></div>
        <span
          [ngClass]="{'them-time': message.originator === 'user', 'me-time': message.originator === 'assistant'}"
          class="timestamp"
        >
          {{message.created | date:'MMM d, y, h:mm a'}}
      </span>
      </div>
    </div>

    <div class="reply-input">
      <div class="row align-items-center">
        <div class="col-md-9">
          <div class="input-group">
            <textarea rows="3" placeholder="Reply..." [(ngModel)]="replyMessage"
            [ngModelOptions]="{standalone: true}" class="form-control reply-field"></textarea>
          </div>
        </div>
        <div class="col-md-3">
          <button class="button button-dark button-small submit-button" [disabled]="sendingMessage" (click)="sendMessage($event)"><span *ngIf="sendingMessage" class="spinner-border spinner-border-sm mr-1"></span>Send</button>
        </div>
      </div>
    </div>
  </div>
  
  <!-- CALL THREAD UI-->
  <div class="transcript-wrapper">
    <div *ngIf="thread.callId">
      <ng-container *ngIf="voiceRecordingSignedUrl">
        <h5>Call Recording:</h5>
        <figure>
          <audio controls [src]="voiceRecordingSignedUrl"></audio>
        </figure>
      </ng-container>
      <h5>Summary:</h5>
      <div class="alert alert-info">{{thread.summary}}</div>
      <h5>Transcript:</h5>
      <div>
        <div *ngFor="let message of threadMessages; let i = index">
          <strong *ngIf="message.originator === 'user'">Caller:</strong>
          <strong *ngIf="message.originator === 'assistant'">AI:</strong>
          <p class="transcript-item">{{message.message}} - <span>{{message.created | date:'MMM d, y, h:mm:ss a'}}</span></p>
        </div>
      </div>
    </div>
  </div>
</div>