import { AfterViewInit, Component, Input, OnInit, ChangeDetectorRef, OnDestroy, Injector } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { ITemplate } from '@nurtureboss/common/dist/types/templates';
import { MediaService } from '@app/_services/media.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpamLanguageService, TemplateFormService, TemplatesService, ToastService } from '@app/_services';
import { Subscription } from 'rxjs';
import { getFileIconClass, isFile } from '@app/_utils/fileIcons';
import { NurtureBossModalService } from '@app/_services/modal.service';
import MODAL_NAMES from '@app/_components/nb-modal/modalTypes';
import { getAllAllowedExtensions } from '@app/_utils/fileIcons';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

// TODO: Mote to constants
const CONCESSION = 'concession';

@Component({
  selector: 'app-template-form-group',
  templateUrl: './template-form-group.component.html',
  styleUrls: ['./template-form-group.component.less'],
  providers: [NgbActiveModal],
})
export class TemplateFormGroupComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() activeControl: string;
  @Input() template: ITemplate;
  @Input() viewData: any;
  @Input() enableDefaults = false;
  @Input() userDefaults: any = {};
  @Input() shouldCheckSpamLanguage = false;
  @Input() useSpamLanguageService = true;

  loading = false;
  mediaUploading = false;
  fileInputTypes = ['file', 'image'];
  generateAITextNames = ['headerText', 'mainText'];
  // TODO: add new type with text and icon to use in DB
  prependInputNames = [CONCESSION]; // hard coded since there is no other identifier for this type of input
  mediaImagesLoading = false;
  activeImageFormControlKey: string | null;
  loadingFormControl: string | null;
  defaultUpdated: {[key: string]: boolean} = {};
  defaultAdded: {[key: string]: boolean} = {};
  defaultRemoved: {[key: string]: boolean} = {};
  changeSubscriptions: Subscription[] = [];
  getFileIconClass = getFileIconClass;
  isFile = isFile;
  getAllAllowedExtensions = getAllAllowedExtensions;
  textFields = [];
  fieldDisplayMap = {};
  spamLanguageService: SpamLanguageService | null = null;

  constructor(
    private cdr: ChangeDetectorRef,
    private mediaService: MediaService,
    private templateFormService: TemplateFormService,
    private nbModalService: NurtureBossModalService,
    private templatesService: TemplatesService,
    private toastService: ToastService,
    private injector: Injector,
  ) {
    // no-op
  }

  ngOnInit() {
    this.loading = true;

    if (this.useSpamLanguageService) {
      this.spamLanguageService = this.injector.get(SpamLanguageService);

      this.spamLanguageService.setContext(this.templateFormService.templateDefaults);
    }

    this.loadTextFieldsAndDisplayMap();
    this.initDefaultsChangedHandler();
  }

  // Used to get a list of 'text' or 'tokentext' fields that will be checked for spam language
  loadTextFieldsAndDisplayMap() {
    for (const field in this.viewData.fields) {
      const fieldData = this.viewData.fields[field];
      if (fieldData.type === 'text' || fieldData.type === 'tokentext') {
        this.textFields.push(fieldData.name);
      }

      this.fieldDisplayMap[fieldData.name] = fieldData.display;
    }
  }

  ngOnDestroy() {
    for (const sub of this.changeSubscriptions) {
      sub.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.loading = false;
    this.cdr.detectChanges();
  }

  initDefaultsChangedHandler() {
    const group = this.templateFormService.getFormGroupByKey(this.activeControl);

    // Set Default Values
    for (const key of Object.keys(group.controls)) {
      const sub = group.controls[key].valueChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((value) => {
        if (this.useSpamLanguageService && this.textFields.includes(key)) {
          this.spamLanguageService.checkForSpamLanguage(this.fieldDisplayMap[key], value);
        }
        if (!this.enableDefaults) {
          return;
        }
        this.userDefaults[key] = value;
        this.templatesService.updateTemplateDefaults(this.template.templateName, this.userDefaults).subscribe((defaults: any) => {
          this.userDefaults = {...defaults.result};
          if (this.useSpamLanguageService ) {
            // Update context
            this.spamLanguageService.addContext(this.userDefaults);
            // Recheck any tokentext inputs 
            // (right now only mainText and headerText use tokens, this might have to be changed to be dynamic if other tokentext fields are used)
            if (group.controls['mainText']) {
              this.spamLanguageService.checkForSpamLanguage('Main Message', group.controls['mainText'].value);
            }
            if (group.controls['headerText']) {
              this.spamLanguageService.checkForSpamLanguage('Main Header', group.controls['headerText'].value);
            }
          }
          if (!value) {
            this.setFieldDefaultMessages(key, 'defaultRemoved');
            this.clearDefaultUpdateMessage(key);
          } else {
            this.setFieldDefaultMessages(key, 'defaultUpdated');
            this.clearDefaultUpdateMessage(key);
          }
        }, (err) => {
          this.toastService.showError('There was an error updating your template defaults.');
        });
      });
      this.changeSubscriptions.push(sub);

      // Check text or tokentext fields for spam language
      if (this.useSpamLanguageService && this.textFields.includes(key)) {
        this.spamLanguageService.checkForSpamLanguage(this.fieldDisplayMap[key], group.controls[key].value);
      }
    }
  }

  openModal(formControlKey) {
    this.activeImageFormControlKey = formControlKey;
    this.nbModalService.open(MODAL_NAMES.MEDIA_MANAGER);
  }

  removeImageClick(controlKey: string) {
    const control = this.templateFormService.getControlByKeys(this.activeControl, controlKey);
    control.setValue('');
    const sub = control.valueChanges.subscribe(async (_) => {
      await this.handleFileUpload(controlKey, sub);
    });
  }

  async handleFileUpload(key: string, sub: Subscription) {
    try {
      const inputElement: any = document.getElementById(key);
      let files = null;
      if (inputElement && inputElement.files) {
        files = inputElement.files;
      }
      if (!files || !files.length) {
        return;
      }
      const control: AbstractControl = this.templateFormService.getControlByKeys(this.activeControl, key);
      this.loadingFormControl = key;
      this.mediaUploading = true;
      if(this.template.templateName === 'RPM ATX Tower') {
        key = '';
      }
      const uploadMediaResponse =
        await this.mediaService.getSignedRequestHelper(files, this.templateFormService.getFormGroupByKey(this.activeControl), key);
      control.setValue(uploadMediaResponse);
    } finally {
      if (sub) {
        sub.unsubscribe();
      }
      this.mediaUploading = false;
    }
  }

  getImageSource(value: string, inputId: string) {
    const returnValue: string = value;
    const input: any = document.getElementById(inputId);
    if (!input) {
      return value;
    }
    const previewElement: any = document.getElementById(`image-preview-${inputId}`);
    previewElement.src = returnValue;
    input.filename = returnValue;
    return returnValue;
  }

  handleImageSubmitEvent(image) {
    let url = image.url;
    if (this.activeImageFormControlKey === 'bannerImage' && image.bannerUrl && this.template.templateName !== 'RPM ATX Tower') {
      url = image.bannerUrl;
    } else if (this.activeImageFormControlKey === 'leftLogo' && image.logoUrl) {
      url = image.logoUrl;
    }

    const control: AbstractControl = this.templateFormService.getControlByKeys(this.activeControl, this.activeImageFormControlKey);
    control.setValue(url);
    this.nbModalService.close(MODAL_NAMES.MEDIA_MANAGER);
  }

  setFieldDefaultMessages(fieldName: string, fieldToKeep: string) {
    this.defaultRemoved[fieldName] = false;
    this.defaultUpdated[fieldName] = false;
    this.defaultAdded[fieldName] = false;
    this[fieldToKeep][fieldName] = true;
  }

  clearDefaultUpdateMessage(fieldName: string) {
    setTimeout(() => {
      this.defaultRemoved[fieldName] = false;
      this.defaultUpdated[fieldName] = false;
      this.defaultAdded[fieldName] = false;
    }, 3000);
  }

  updateDefault(event: MouseEvent, fieldName: string): void {
    if (Object.keys(this.userDefaults).length === 0) {
      return;
    }

    const control = this.templateFormService.getControlByKeys(this.activeControl, fieldName) as FormControl;
    if (!control) {
      return;
    }

    const checkbox = event.target as HTMLInputElement;
    const addingDefault = checkbox.checked;
    if (addingDefault) {
      this.userDefaults[fieldName] = control.value;
    } else {
      this.userDefaults[fieldName] = null;
    }
    this.templatesService.updateTemplateDefaults(this.template.templateName, this.userDefaults).subscribe((defaults: any) => {
      this.userDefaults = {...defaults.result};
      if (addingDefault) {
        this.setFieldDefaultMessages(fieldName, 'defaultAdded');
      } else {
        this.setFieldDefaultMessages(fieldName, 'defaultRemoved');
      }
      this.clearDefaultUpdateMessage(fieldName);
    }, (err) => {
      this.toastService.showError('There was an error updating your template defaults.');
    });
  }

  getTokensToShow(): {[key: string]: string} {
    const tokensToUse = this.templateFormService.getRawValues();

    if (this.template.templateName === 'Delinquency') {
      tokensToUse.ledgerBalance = '$367.45';
    }

    // Add prospect contact info.
    tokensToUse.clientFirstName = 'Nelli';
    return tokensToUse;
  }
}
