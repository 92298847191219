import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';

@Injectable({ providedIn: 'root' })
export class VoiceVLAService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  getVoiceAssistant(id) {
    return this.http.get<any>(this.apiHelper.fillUrl('voiceAssistant', { id }, {}))
      .pipe(map(data => {
        return data;
    }));
  }
  
  updateCallTransferDestinations(id, data) {
    return this.http.put<any>(this.apiHelper.fillUrl('voiceCallTransferDestinations', { id }, {}), data)
      .pipe(map(data => {
        return data;
    }));
  }

  updateCallTransferDestinationNumber(id, type, number) {
    return this.http.put<any>(this.apiHelper.fillUrl('voiceCallTransferDestinationNumber', { id, type }, {}), { number })
    .pipe(map(data => {
      return data;
  }));
  }
}
