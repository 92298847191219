import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';

interface IContactsQuery {
  skip: number;
  take: number;
  query?: string;
  paginate: boolean;
};

@Injectable({ providedIn: 'root' })
export class ContactsService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  getContacts() {
    return this.http.get<any>(this.apiHelper.fillUrl('contacts', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getPaginatedContacts(skip, take, query?) {
    const payload: IContactsQuery = {
      skip: skip,
      take: take,
      paginate: true,
    };
    if (query) {
      payload.query = JSON.stringify(query);
    }
    return this.http.get<any>(this.apiHelper.fillUrl('contacts', {}, payload))
      .pipe(map(data => {
        return data;
      }));
  }

  getHotLeads() {
    return this.http.get<any>(this.apiHelper.fillUrl('getHotLeads', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getContactsWorker(id) {
    return this.http.get<any>(this.apiHelper.fillUrl('contactsWorker', {id: id}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getContact(id) {
    return this.http.get<any>(this.apiHelper.fillUrl('contact', {id: id}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getContactsPages(id) {
    return this.http.get<any>(this.apiHelper.fillUrl('contactsPages', {id: id}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getContactsTexts(id) {
    return this.http.get<any>(this.apiHelper.fillUrl('contactsTexts', {id: id}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  optOutContact(id, payload) {
    return this.http.post<any>(this.apiHelper.fillUrl('optout', {id: id}, {}), payload)
      .pipe(map(data => {
        return data;
      }));
  }

  checkOptInStatus(id) {
    return this.http.get<any>(this.apiHelper.fillUrl('optout', {id: id}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  addLabelToContacts(payload) {
    return this.http.post<any>(this.apiHelper.fillUrl('contactLabels', {}, {}), payload)
      .pipe(map(data => {
        return data;
      }));
  }

  createContacts(payload) {
    return this.http.post<any>(this.apiHelper.fillUrl('contacts', {}, {}), payload)
      .pipe(map(data => {
        return data;
      }));
  }

  deleteContact(id) {
    return this.http.delete<any>(this.apiHelper.fillUrl('contact', {id: id}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getLabels() {
    return this.http.get<any>(this.apiHelper.fillUrl('contactLabels', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getContactMetadata() {
    return this.http.get<{ result: { statuses: string[]; sources: string[]; }; }>(this.apiHelper.fillUrl('contactMetadata', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  markContactAsLost(id) {
    return this.http.post<any>(this.apiHelper.fillUrl('markContactAsLost', {id: id}, {}), {})
      .pipe(map(data => {
        return data;
      }));
  }

  determineContactType(userClaims) {
    let claims = '';
    for (const claim of userClaims) {
      switch (claim) {
        case 'yardi':
        case 'realpage':
        case 'entrata':
        case 'resman':
        case 'knock':
          claims = claims + claim + ',';
      }
    }
    return claims;
  }
}
