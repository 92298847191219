import { Component, OnInit} from '@angular/core';
import {
  AuthenticationService,
  LoaderService,
  ToastService,
  VLAService,
  UsersService,
} from '@app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vla',
  templateUrl: './vla.component.html',
  styleUrls: ['./vla.component.less']
})
export class VLAComponent implements OnInit {

  userData: any;
  adjustedHeight: string;
  isAdmin = false;
  vlaHref = '';
  isEmailAndTextVlaEnabled = false;
  isVoiceVlaEnabled = false;
  isEnablingVla = false;

  constructor(
    private authService: AuthenticationService,
    public router: Router,
    private vlaService: VLAService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private usersService: UsersService,
  ) {
    this.userData = this.authService.currentUserValue.user;
    this.vlaHref = `mailto:sales@nurtureboss.io?subject=Id like to enable the VLA on my account&body=Please contact me to enable the VLA on my account at property ${this.userData.propertyName}!`
  }

  ngOnInit(): void {

    // Adjust CSS to give CS a good experience too :)
    this.isAdmin = this.userData.claims.includes('admin');

    if (this.userData.vlaAssistantId && this.userData.textMessageAssistantId) {
      this.isEmailAndTextVlaEnabled = true;
    }

    if (this.userData.voiceAssistantId) {
      this.isVoiceVlaEnabled = true;
    }

    if (this.isAdmin) {
      this.adjustedHeight = 'calc(100vh - 152px)';
    } else {
      this.adjustedHeight = 'calc(100vh - 68px)';
    }
  }

  vlaEnabled() {
    return this.authService.currentUserValue?.user?.claims?.includes('vla') && !this.isEnablingVla;
  }

  async enableVLA(type) {
    try {
      // This doesn't get set to false since page will be reloaded
      // Is needed since to avoid weird poping when page reloads
      if (!this.authService.currentUserValue?.user?.voiceAssistantId && !this.authService.currentUserValue?.user?.vlaAssistantId) {
        this.isEnablingVla = true;
      }
      this.loaderService.triggerLoader();
      if (type === 'voice') {
        await this.vlaService.enableVoiceVLA().toPromise();
        this.toastService.showSuccess('Voice VLA Enabled');
      } else if (type = 'email-text') {
        await this.vlaService.enableVLA().toPromise();
        this.toastService.showSuccess('Email / Text VLA Enabled');
      } else {
        throw new Error('Invalid type');
      }

      this.usersService.loadUser();
      
      // Wait 2 seconds so that loadUser can run and get the updated user
      // Otherwise the enable button stays on the page
      setTimeout(async () => {
        window.location.reload();
        this.loaderService.stopLoader();
      }, 2000);
    } catch (e) {
      this.loaderService.stopLoader();
      this.toastService.showError('Could not enable Voice VLA');
    }
  }
}
