import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class APIHelperService {

  endpoints: any;
  baseUrl: string;
  eventsUrl: string;
  textsUrl: string;
  emailsUrlWithoutProtocol: string;
  emailsUrl: string;
  pageTemplateBaseUrlWithoutProtocol: string;
  pageTemplateBaseUrl: string;
  emailTemplateBaseUrlWithoutProtocol: string;
  emailTemplateBaseUrl: string;

  constructor() {

    // For production.
    this.baseUrl = 'https://app.nurtureboss.io/';
    this.eventsUrl = 'https://events.nurtureboss.io/';
    this.textsUrl = 'https://texts.nurtureboss.io/';
    this.emailsUrlWithoutProtocol = '//emails.nurtureboss.io/';
    this.pageTemplateBaseUrlWithoutProtocol = '//aptlife.co/';

    // For development.
    // this.baseUrl = 'http://localhost:5001/';
    // this.eventsUrl = 'http://localhost:8081/';
    // this.textsUrl = 'http://localhost:5002/';
    // this.pageTemplateBaseUrl = 'http://localhost:4201';

    // for dev and prod. no need to change
    this.emailsUrl = `https:${this.emailsUrlWithoutProtocol}`;
    this.pageTemplateBaseUrl = `https:${this.pageTemplateBaseUrlWithoutProtocol}`;
    this.emailTemplateBaseUrlWithoutProtocol = `${this.emailsUrlWithoutProtocol}api/emails/preview`;
    this.emailTemplateBaseUrl = `https:${this.emailTemplateBaseUrlWithoutProtocol}`;

    this.endpoints = {
      login: this.baseUrl + 'api/login',
      register: this.baseUrl + 'api/register',
      pages: this.baseUrl + 'api/pages',
      bulkpages: this.baseUrl + 'api/pages/bulkpages',
      singlePage: this.baseUrl + 'api/pages/:id',
      pageClicks: this.baseUrl + 'api/pages/clicks',
      media: this.baseUrl + 'api/media',
      mediaById: this.baseUrl + 'api/media/:id',
      singleMedia: this.baseUrl + 'api/media/:id',
      s3SignRequest: this.baseUrl + 'api/media/sign',
      subscribes: this.baseUrl + 'api/subscriptions',
      subscribe: this.baseUrl + 'api/subscriptions/:id',
      subscribeCancel: this.baseUrl + 'api/subscriptions/cancel',
      users: this.baseUrl + 'api/users',
      user: this.baseUrl + 'api/users/:id',
      userPassword: this.baseUrl + 'api/users/password/:id',
      invoices: this.baseUrl + 'api/subscriptions/invoices',
      paymentMethods: this.baseUrl + 'api/paymentMethods',
      paymentAttach: this.baseUrl + 'api/paymentMethods/attach',
      getTextMessages: this.baseUrl + 'api/textMessages',
      textMessages: this.textsUrl + 'api/textMessages',
      textMessagesBulk: this.textsUrl + 'api/textMessages/bulk',
      labels: this.baseUrl + 'api/labels',
      label: this.baseUrl + 'api/labels/:id',
      templateDefault: this.baseUrl + 'api/templateDefaults/:templateName',
      templateDefaultById: this.baseUrl + 'api/templateDefaults/v2/:id',
      templateDefaultsForUserAutomations: this.baseUrl + 'api/templateDefaults/v2/automations/:id',
      templateDefaultsStoreAutomationDefault: this.baseUrl + 'api/templateDefaults/v2/automations',
      templateDefaultsForUserAlert: this.baseUrl + 'api/templateDefaults/v2/alert/:alertId',

      // TODO: Once we have templates in DB update templateDefaultById to look like this.
      // 'templateDefaultById': this.baseUrl + 'api/templates/:templateId/defaults/:templateName',
      templateDefaults: this.baseUrl + 'api/templateDefaults',
      textTemplates: this.baseUrl + 'api/textTemplates',
      textTemplate: this.baseUrl + 'api/textTemplates/:id',
      resetPasswordLink: this.baseUrl + 'api/resetPassword',
      updatePassword: this.baseUrl + 'api/resetPassword/update',
      analytics: this.eventsUrl + 'api/analytics',
      realPageProspects: this.baseUrl + 'api/realPage/prospects',
      realPageWorkers: this.baseUrl + 'api/realPage/workers/:id',
      realPageSources: this.baseUrl + 'api/realPage/sources',
      realPageSourcesQuery: this.baseUrl + 'api/realPage/sources-query',
      realPageAdminDetailsStart: this.baseUrl + 'api/realPage/admin-details',
      realPageAdminDetails: this.baseUrl + 'api/realPage/admin-details/:jobId',
      realPageAgents: this.baseUrl + 'api/realPage/agents',
      terms: this.baseUrl + 'api/terms',
      schedules: this.baseUrl + 'api/schedules',
      scheduleEmail: this.baseUrl + 'api/schedules/email',
      contacts: this.baseUrl + 'api/contacts',
      contactsWorker: this.baseUrl + 'api/contacts/workers/:id',
      contact: this.baseUrl + 'api/contacts/:id',
      contactsPages: this.baseUrl + 'api/contacts/:id/pages',
      contactsTexts: this.baseUrl + 'api/contacts/:id/texts',
      contactMetadata: this.baseUrl + 'api/contacts/metadata',
      conversations: this.baseUrl + 'api/conversations',
      conversation: this.baseUrl + 'api/conversations/:id',
      reply: this.textsUrl + 'api/conversations/:id/reply',
      unread: this.baseUrl + 'api/conversations/unread',
      adminAccounts: this.baseUrl + 'api/admin/accounts',
      mimicAccount: this.baseUrl + 'api/admin/mimic/:id',
      groupMimicAccount: this.baseUrl + 'api/groups/mimic/:id',
      yardiProspects: this.baseUrl + 'api/yardi/prospects',
      yardiConfiguration: this.baseUrl + 'api/yardi/configuration',
      yardiILSConfiguration: this.baseUrl + 'api/yardi/ILS-configuration',
      yardiResidentPermissions: this.baseUrl + 'api/yardi/resident-permissions',
      yardiLeaseRenewalPermissions: this.baseUrl + 'api/yardi/lease-renewal-permissions',
      yardiWorkers: this.baseUrl + 'api/yardi/workers/:id',
      defaults: this.baseUrl + 'api/defaults',
      globalDefaults: this.baseUrl + 'api/defaults/global',
      selectedDefaults: this.baseUrl + 'api/defaults/selected',
      usages: this.baseUrl + 'api/admin/usages',
      yardiSources: this.baseUrl + 'api/yardi/sources',
      yardiSourcesQuery: this.baseUrl + 'api/yardi/sources-query',
      yardiSource: this.baseUrl + 'api/yardi/sources/:id',
      userAutomations: this.baseUrl + 'api/users/:id/automations',
      realestateAutomation: this.baseUrl + 'api/automations/realestate',
      automationsSent: this.baseUrl + 'api/automations/steps/:stepId/count',
      automationSettings: this.baseUrl + 'api/automationSettings/:userId',
      automationSettingSources: this.baseUrl + 'api/automationSettings/:userId/sources',
      automationSettingDelay: this.baseUrl + 'api/automationSettings/:userId/delay',
      emails: this.emailsUrl + 'api/emails',
      createBulkEmails: this.emailsUrl + 'api/emails/bulkobjects',
      sendEmail: this.emailsUrl + 'api/emails/send',
      sendEmailTemplate: this.emailsUrl + 'api/emails/template',
      sendBulkEmails: this.emailsUrl + 'api/emails/bulk',
      createEmailRoute: this.emailsUrl + 'api/emails/route',
      optout: this.baseUrl + 'api/contacts/:id/optout',
      contactLabels: this.baseUrl + 'api/contacts/labels',
      textSubaccounts: this.textsUrl + 'api/subaccounts',
      dashboardAuthenticate: this.baseUrl + 'api/dashboards/authenticate',
      brandings: this.baseUrl + 'api/brandings',
      branding: this.baseUrl + 'api/brandings/:id',
      getEmails: this.baseUrl + 'api/emails',
      knowledgeBaseAuthenticate: this.baseUrl + 'api/knowledgeBase',
      getTemplates: this.baseUrl + 'api/templates',
      getTemplatesByName: this.baseUrl + 'api/templates/:name',
      byoa: this.baseUrl + 'api/automations/v2',
      byoaQuery: this.baseUrl + 'api/automations/v2/query',
      singleByoa: this.baseUrl + 'api/automations/v2/:id',
      notifications: this.baseUrl + 'api/notifications',
      notification: this.baseUrl + 'api/notifications/:id',
      broadcasts: this.baseUrl + 'api/broadcasts',
      broadcast: this.baseUrl + 'api/broadcasts/:id',
      broadcastRecipients: this.baseUrl + 'api/broadcasts/:id/recipients',
      generateWidgetToken: this.baseUrl + 'api/widget/token',
      entrataSources: this.baseUrl + 'api/entrata/sources',
      entrataEventTypes: this.baseUrl + 'api/entrata/eventTypes',
      entrataAgents: this.baseUrl + 'api/entrata/agents',
      entrataSourcesQuery: this.baseUrl + 'api/entrata/sources-query',
      entrataWorkers: this.baseUrl + 'api/entrata/workers/:id',
      entrataSource: this.baseUrl + 'api/entrata/sources/:id',
      entrataAgent: this.baseUrl + 'api/entrata/agents/:id',
      widgetFeatures: this.baseUrl + 'api/users/widgetFeatures',
      churn: this.baseUrl + 'api/users/:id/churn',
      integrationProperty: this.baseUrl + 'api/integrations/property/:propertyId',
      updateIntegrationProperty: this.baseUrl + 'api/integrations/property/:propertyId/:integrationType',
      getEntrataProperties: this.baseUrl + 'api/entrata/properties',
      getKnockCommunities: this.baseUrl + 'api/knock/communities',
      getKnockCommunityAgents: this.baseUrl + 'api/knock/communities/:id/agents',
      adminGetClientSuccessAccountTypes: this.baseUrl + 'api/admin/client-success-account-types',
      settingsByType: this.baseUrl + 'api/settings/:type',
      allSettings: this.baseUrl + 'api/settings/',
      groups: this.baseUrl + 'api/groups',
      group: this.baseUrl + 'api/groups/:id',
      verifyIntegration: this.baseUrl + 'api/integrations/verify/:type',
      getRentgrataListings: this.baseUrl + 'api/rentgrata/listings',
      partnerDetail: this.baseUrl + 'api/partnerDetails/:partnerId',
      contactEvents: this.eventsUrl + 'api/events/contacts/:contactId',
      getHotLeads: this.baseUrl + 'api/contacts/hotleads',
      userAudits: this.baseUrl + 'api/userAudits',
      triggeredUserAudits: this.baseUrl + 'api/userAudits/triggered',
      // Update Lost Lead Trigger Code
      lostLeadAutomation: this.baseUrl + 'api/automations/v2/lostLeadAutomation/:id',
      // Update Delinquency Trigger Code
      delinquencyAutomation: this.baseUrl + 'api/automations/v2/delinquencyAutomation/:id',
      deactivateDelinquencyAutomation: this.baseUrl + 'api/automations/v2/delinquencyAutomation/deactivate',
      leasingAgents: this.baseUrl + 'api/integrations/property/:propertyId/agents/:integrationType',
      sources: this.baseUrl + 'api/integrations/property/:propertyId/sources/:integrationType',
      lostReasons: this.baseUrl + 'api/integrations/property/:propertyId/lostReasons/:integrationType',
      getLedgerDataPermission: this.baseUrl + 'api/integrations/property/:propertyId/ledger-data-permission/:integrationType',
      getLeaseRenewalPermission: this.baseUrl + 'api/integrations/property/:propertyId/lease-renewal-permission',
      alert: this.baseUrl + 'api/alerts/:alertId',
      alerts: this.baseUrl + 'api/alerts',
      alertByName: this.baseUrl + 'api/alerts/:name',
      sentAlertsCount: this.baseUrl + 'api/alerts/:alertId/sent-count',
      openAIGradeSubjectLine: this.baseUrl + 'api/openAI/gradeSubjectLine',
      openAIGenerateSubjectLine: this.baseUrl + 'api/openAI/generateSubjectLine',
      openAIGenerateTextWithPropertyContext: this.baseUrl + 'api/openAI/generateTextWithPropertyContext',
      forms: this.baseUrl + 'api/forms',
      form: this.baseUrl + 'api/forms/:id',
      formResponses: this.baseUrl + 'api/forms/:id/responses',
      threads: this.baseUrl + 'api/threads',
      thread: this.baseUrl + 'api/threads/:id',
      threadVoiceRecordingSignedUrl: this.baseUrl + 'api/threads/:id/voice-recording-signed-url/',
      threadMessages: this.baseUrl + 'api/threads/:id/messages',
      vla: this.baseUrl + 'api/vla',
      voiceVla: this.baseUrl + 'api/vla/voice',
      markContactAsLost: this.baseUrl + 'api/contacts/:id/mark-as-lost',
      voiceAssistant: this.baseUrl + 'api/voice/assistants/:id',
      voiceCallTransferDestinations: this.baseUrl + 'api/voice/assistants/:id/callTransferDestinations',
      voiceCallTransferDestinationNumber: this.baseUrl + 'api/voice/assistants/:id/callTransferDestinations/:type/number',
    };
  }

  fillUrl(urlFormat:string, pathParams:any, queryParams:any) {
    var url = this.endpoints[urlFormat];
    for (let name in pathParams) {
      if (typeof(pathParams[name]) === 'undefined' || pathParams[name] === null || pathParams[name] === '') {
        url = url.replace(new RegExp('/:' + name, 'g'), '');
      } else {
        url = url.replace(new RegExp(':' + name, 'g'), pathParams[name]);
      }

    }
    if (Object.keys(queryParams).length > 0) {
      var formattedQueryParams = [];
      for (let param in queryParams) {
        formattedQueryParams.push('' + param + '=' + queryParams[param]);
      }
      url += '?' + formattedQueryParams.join('&');
    }
    return url;
  }

  getWebSocketEndpoint(): any {
    return {
      webApp: this.baseUrl.replace('http', 'ws'),
      texting: this.textsUrl.replace('http', 'ws')
    };
  }
}
