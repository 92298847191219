<div>
  <div class="row">
    <div class="col-sm-2">
      <ul class="innder-side-nav" [ngStyle]="{'min-height': adjustedHeight}">
        <li [class.active]="router.url === '/settings/account'">
          <i class="far fa-user-circle"></i> <a routerLink="/settings/account">Account</a>
        </li>
        <li [class.active]="router.url === '/settings/bot'" *ngIf="userData.claims.includes('bot')">
          <i class="fa-regular fa-robot-astromech"></i> <a routerLink="/settings/bot">Knowledge Base</a>
        </li>
        <li [class.active]="router.url === '/settings/defaults'" *ngIf="userData.claims.indexOf('real estate') === -1 || userData.claims.indexOf('admin') > -1">
          <i class="far fa-check-square"></i> <a routerLink="/settings/defaults">Defaults</a>
        </li>
        <li [class.active]="router.url === '/settings/compliance'">
          <i class="far fa-file-contract"></i> <a routerLink="/settings/compliance">Compliance</a>
        </li>
        <li [class.active]="router.url === '/settings/integrations'">
          <i class="far fa-sync-alt"></i> <a routerLink="/settings/integrations">Integrations</a>
        </li>
        <li [class.active]="router.url === '/settings/media'">
          <i class="far fa-images"></i> <a routerLink="/settings/media">Media</a>
        </li>
        <li [class.active]="router.url === '/settings/notifications'">
          <i class="far fa-flag"></i> <a routerLink="/settings/notifications">Notifications</a>
        </li>
        <li [class.active]="router.url === '/settings/billing'" *ngIf="!userData.claims.includes('partner') && !userData.partnerOwnerId">
          <i class="far fa-credit-card"></i> <a routerLink="/settings/billing">Billing</a>
        </li>
        <li [class.active]="router.url === '/settings/vla'" *ngIf="userData.claims.includes('vla') && userData.voiceAssistantId">
          <i class="fa-regular fa-brain-circuit"></i> <a routerLink="/settings/vla">VLA</a>
        </li>
      </ul>
    </div>
    <div class="col-sm-10">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
