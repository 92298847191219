// Libs
import { Component, OnInit, HostListener } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ITemplate, TemplateMessagingTypes } from '@nurtureboss/common/dist/types/templates';
import { forkJoin } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PagePreviewService } from '@app/_services/pagePreview.service';
import { AutomationSettingsService } from '@app/_services/automationSettings.service';
// Common
import { AlertSchedulingMode, Alert } from '@nurtureboss/common/dist/types/alerts';
import { AutomationSettings } from '@nurtureboss/common/dist/types/automationSettings';

import { User } from '@nurtureboss/common/dist/types/users';

import {
  AlertsService,
  AuthenticationService,
  BrandingsService,
  ContactsService,
  IntegrationService,
  LoaderService,
  RealPageService,
  TemplatesService,
  ToastService,
  UsersService,
} from '@app/_services';
import { CanDeactivateComponent } from '@app/_helpers/pending-changes.guard';

const NEW_TEMPLATE_DEFAULT_VERSION = 2;

const alertNameToAutomationSettingsKey = {
  'Price Drop Alert': {
    'delay': 'priceDropDelay',
  },
  'Availability Alert': {
    'delay': 'availabilityDelay',
  },
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.less']
})
export class AlertComponent implements OnInit, CanDeactivateComponent {
  alert: Alert | null = null;
  automationSettings: AutomationSettings;
  alertForm: FormGroup;
  automationSettingsForm: FormGroup;
  alertType: 'Price Drop Alert' | 'Availability Alert' = 'Price Drop Alert';
  sentAlertsCount: number | null = null;
  branding: { fontFamily: string; primaryColor: string; };
  forcedTemplateDisplay: string | null = null;
  pagePreviewUrl: SafeResourceUrl = null;
  previewUrl: SafeResourceUrl = null;
  saveErrorMessages: string[] = [];
  selectedTemplateDefault: any;
  selectedTemplate: ITemplate;
  templateDefaults: any[] = [];
  templateDefaultIsValid: boolean = false;
  templates: ITemplate[] = [];
  templateSupportsEmail = false;
  templateSupportsText = false;
  templateTokens: Map<string, string[]>;
  user: User = null;
  alertDelay: number = 0;

  constructor(
    private alertsService: AlertsService,
    private authService: AuthenticationService,
    private brandingService: BrandingsService,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private modalService: NgbModal,
    private pagePreviewService: PagePreviewService,
    private route: ActivatedRoute,
    private router: Router,
    private templateService: TemplatesService,
    private toastService: ToastService,
    private usersService: UsersService,
    private integrationService: IntegrationService,
    private automationsSettingsService: AutomationSettingsService,
  ) {
    //no-op
  }

  ngOnInit(): void {
    this.initFields();
    this.loadAlert();
  }

  @HostListener('window:beforeunload')
  canDeactivate() {
    if (this.alertForm.pristine) {
      return true;
    }

    return confirm('There are some pending changes that have not been saved. If you leave the page they will be lost. Are you sure you want to leave the page?');
  }

  initFields() {
    this.alertForm = new FormGroup({
      active: new FormControl(false),
      templateId: new FormControl('', [
        Validators.required,
      ]),
      emailSubjectLine: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      textMessage: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      schedulingMode: new FormControl(false, [
        Validators.required,
      ]),
    });

    this.automationSettingsForm = new FormGroup({
      delay: new FormControl(0, [
        Validators.min(0),
      ]),
    });
  }

  async checkUserHasAlertPermissions () {
    try {
      const integrationProperty = await this.integrationService.getPropertyInfo(this.authService.currentUserValue.user.integrationPropertyId).toPromise();
      if (!integrationProperty.availabilitySource) {
        this.router.navigate([`/automations`], {});
      }
    } catch (e) {
      this.router.navigate([`/automations`], {});
    }
  }

  async loadAlert() {
    this.loaderService.triggerLoader();
    await this.checkUserHasAlertPermissions()

    const observables = [
      this.alertsService.getAlertByName(this.route.snapshot.queryParams['name']),
      this.templateService.getTemplates(),
      this.brandingService.getBrandings(),
      this.automationsSettingsService.getAutomationSettings(this.authService.currentUserValue.user._id),
    ];

    forkJoin(observables).subscribe(async (resultsArray) => {
      // Load Global Defaults
      await this.usersService.loadGlobalDefaults();

      this.user = this.authService.currentUserValue.user;
      this.alert = resultsArray[0].result as Alert;
      this.alertType = this.alert.name as 'Price Drop Alert' | 'Availability Alert';
      this.templates = resultsArray[1].result;
      this.branding = resultsArray[2].result || {
        fontFamily: 'Alegreya Sans',
        primaryColor: '#235587',
      };
      this.automationSettings = resultsArray[3].result;
      this.alertDelay = this.alertType === "Price Drop Alert" ? this.automationSettings.priceDropDelay : this.automationSettings.availabilityDelay;
      this.automationSettingsForm.controls.delay.setValue(this.alertDelay);
      this.sentAlertsCount = (await this.alertsService.getSentAlertsCount(this.alert._id).toPromise()).result;      
      // Init Templates
      this.templates = this.templates.filter((template) =>
        (!template.authorizedClaims || !template.authorizedClaims.length)
        || (template.authorizedClaims.filter((claim) => this.user.claims.includes(claim)).length > 0)
      );
      this.templates = this.templates.filter(({_id}) => this.alert.templateRestrictions.includes(_id.toString()));
      this.templates.sort((x, y) => x.templateName.localeCompare(y.templateName));
      this.selectedTemplate = this.findTemplateById(this.alert.templateId);

      this.templateTokens = this.getTemplateTokens();

      // Init Action TemplateDefaults
      this.templateDefaults = (await this.templateService.getUserAlertTemplateDefaults(this.alert._id).toPromise()).result;
      // Sort is used later when grabbing the most recent record.
      this.templateDefaults = this.templateDefaults.sort((a, z) => {
        return +new Date(z.updated) - +new Date(a.updated);
      });
      this.selectedTemplateDefault = this.getTemplateDefault(this.alert.templateDefaultId);
      this.validateSelectedTemplateDefault();

      this.regenPreviewUrls()

      // Init form with values
      const formValues: any = {
        active: this.alert.active,
        templateId: this.selectedTemplate._id,
        emailSubjectLine: this.alert.emailSubjectLine,
        textMessage: this.alert.textMessage,
        schedulingMode: this.getSchedulingModeByType(this.alert.schedulingMode, 'boolean'),
      };
      this.alertForm.setValue(formValues);
      this.alertForm.markAsPristine({ onlySelf: false });

      this.alertForm.controls.templateId.valueChanges.subscribe(templateId => {
        this.selectedTemplate = this.findTemplateById(templateId);
        this.selectedTemplateDefault = this.getTemplateDefault(templateId);
        this.templateSupportsEmail = this.selectedTemplate.supportedMessaging.includes(TemplateMessagingTypes.Email);
        this.templateSupportsText = this.selectedTemplate.supportedMessaging.includes(TemplateMessagingTypes.NurturePage);
        this.validateSelectedTemplateDefault();
    
        if (this.templateSupportsText && !this.alertForm.controls.textMessage.value) {
          this.alertForm.controls.textMessage.setValue(this.selectedTemplate.defaultTextMessageContent, {
            onlySelf: true,
            emitEvent: false,
          });
        } else if (!this.templateSupportsText) {
          this.alertForm.controls.textMessage.setValue('Text messaging not supported', {
            onlySelf: true,
            emitEvent: false,
          });
        }
  
        if (this.templateSupportsEmail && !this.alertForm.controls.emailSubjectLine.value) {
          this.alertForm.controls.emailSubjectLine.setValue(this.selectedTemplate.defaultEmailSubjectLine, {
            onlySelf: true,
            emitEvent: false,
          });
        } else if (!this.templateSupportsEmail) {
          this.alertForm.controls.emailSubjectLine.setValue('Email not supported', {
            onlySelf: true,
            emitEvent: false,
          });
        }
        this.regenPreviewUrls();
      })

      this.loaderService.stopLoader();
    }, (error) => {
      this.showUserError('Error loading alert data.');
      this.loaderService.stopLoader();
    });
  }

  getSchedulingModeByType(value, type) {
    if (type === 'boolean') {
      if (value === AlertSchedulingMode.Optimize) {
        return true;
      } else {
        return false;
      }
    }

    if (type === 'string') {
      if (value) {
        return AlertSchedulingMode.Optimize;
      } else {
        return AlertSchedulingMode.Immediate;
      }
    }
  }

  updateAlert() {
    this.saveErrorMessages = [];
    
    if (!this.validateTemplateDefault()) {
      return;
    }

    this.loaderService.triggerLoader();
    this.updateAlertAndDelay()
      .then(() => {
        this.handleSuccessfulUpdate();
      })
      .catch((err) => {
        this.handleUpdateError(err);
      })
      .finally(() => {
        this.loaderService.stopLoader();
      });
  }

  private validateTemplateDefault(): boolean {
    if (this.alertForm.controls.active.value &&
        !this.templateService.isTemplateDefaultValid(this.selectedTemplate, this.selectedTemplateDefault)) {
      this.showUserError(`Cannot turn on alert with invalid template defaults!`);
      return false;
    }
    return true;
  }

  private updateAlertAndDelay(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.alertsService.updateAlert(
        this.alert._id,
        this.getUpdatedAlertData()
      ).subscribe(
        () => this.updateDelaySettings().subscribe(resolve, reject),
        reject
      );
    });
  }

  private getUpdatedAlertData() {
    return {
      alert: {
        ...this.alertForm.value,
        schedulingMode: this.getSchedulingModeByType(this.alertForm.controls.schedulingMode.value, 'string')
      },
      templateDefault: this.selectedTemplateDefault
    };
  }

  private updateDelaySettings() {
    const delayUpdate = this.getDelayUpdate();
    return this.automationsSettingsService.updateDelay(this.automationSettings._id, delayUpdate);
  }

  private getDelayUpdate() {
    const delayValue = this.automationSettingsForm.controls.delay.value;
    return this.alertType === "Price Drop Alert"
      ? { priceDropDelay: delayValue }
      : { availabilityDelay: delayValue };
  }

  private handleSuccessfulUpdate() {
    this.alertForm.markAsPristine({ onlySelf: false });
    this.automationSettingsForm.markAsPristine({ onlySelf: false });
    this.showUserSuccess('Successfully updated alert and delay settings!');
  }

  private handleUpdateError(err: any) {
    this.displayPossibleSaveErrors(err);
  }

  onToggleChanged() {
    this.alertForm.markAsTouched();
  }

  getTemplateDefault(templateId) {
    // Check for existing template default
    let acceptedDefault = this.templateDefaults.find(templateDefault => {
      return templateDefault.templateId === templateId;
    })

    if (acceptedDefault) {
      return acceptedDefault;
    }

    // Return most recent if none exists
    acceptedDefault = { ...this.templateDefaults[0], templateName: this.selectedTemplate.templateName };
    delete acceptedDefault._id;
    delete acceptedDefault.updated;
    delete acceptedDefault.created;
    return acceptedDefault;
  }

  findTemplateById(templateId: string): ITemplate {
    return this.templates.find((t) => t._id === templateId)!;
  }

  validateSelectedTemplateDefault() {
    this.templateDefaultIsValid = this.templateService.isTemplateDefaultValid(this.selectedTemplate, this.selectedTemplateDefault);
  }

  regenPreviewUrls() {
    let templateDisplay = null;
    this.templateSupportsEmail = this.selectedTemplate.supportedMessaging.includes(TemplateMessagingTypes.Email);
    this.templateSupportsText = this.selectedTemplate.supportedMessaging.includes(TemplateMessagingTypes.NurturePage);

    if (!this.templateSupportsText && this.templateSupportsEmail) {
      templateDisplay = 'email';
    } else if (this.templateSupportsText && !this.templateSupportsEmail) {
      templateDisplay = 'nurturepage';
    }

    this.forcedTemplateDisplay = templateDisplay;
    const dataForPreview = {
      yourWebsite: 'https://example.website.com',
      onlineApplicationUrl: 'https://example.application.com',
      yourPhoneNumber: '5555555555',
      yourEmailAddress: 'example@example.com',
      type: this.selectedTemplate.templateName,
      apartmentName: this.user.propertyName,
      propertyName: this.user.propertyName,
      clientFirstName: 'Nelli',
      appointmentDate: '01/15/2024',
      appointmentTime: '1:30 pm',
      eventName: 'Taco Tuesday',
      eventDate: '01/15/2024',
      eventTime: '1:30 pm',
      eventLocation: 'Outside The Leasing Office',
      apartmentNumber: '1032',
      scheduleATourUrl: 'https://example.website.com',
      floorPlanOne: 'AL',
      floorPlanTwo: 'AU',
      availability: {
        beds: 1,
        baths: 2,
        sqft: '956',
        unitImageURL: 'https://app.nurtureboss.io/assets/sample-floorplan.jpg',
        price: 1899,
        applyOnlineUrl: this.selectedTemplateDefault.applyOnlineUrl || this.selectedTemplateDefault.onlineApplicationUrl || '#',
        specials: 'Ask how you can get discounted monthly rents on select apartments!'
      },
      priceDrop: {
        beds: 1,
        baths: 2,
        sqft: '956',
        unitImageURL: 'https://app.nurtureboss.io/assets/sample-floorplan.jpg',
        price: 1899,
        applyOnlineUrl: this.selectedTemplateDefault.applyOnlineUrl || this.selectedTemplateDefault.onlineApplicationUrl || '#',
        specials: 'Ask how you can get discounted monthly rents on select apartments!'
      },
      ...this.usersService.currentGlobalDefaultSocialLinks,
      ...this.selectedTemplateDefault,
      ...this.branding,
    };
    this.previewUrl =  this.templateSupportsEmail ? this.pagePreviewService.getEmailPreviewUrl(this.selectedTemplate, dataForPreview) : null;
    this.pagePreviewUrl = this.templateSupportsText ? this.pagePreviewService.getPagePreviewUrl(this.selectedTemplate, dataForPreview) : null;
  }

  openDefaults(defaults) {
    this.modalService.open(defaults, { windowClass : 'custom-modal-styles-new email-modal'}).result.then((result) => {
      // no-op
    }, () => {
      // no-op
    });
  }

  handleFormSubmitEvent(formData: {[key: string]: string}) {
    this.selectedTemplateDefault = { ...this.selectedTemplateDefault, ...formData };
    this.validateSelectedTemplateDefault();
    this.regenPreviewUrls();
    this.modalService.dismissAll();
  }

  getTokensToHide(): string[] {
    return [
      'mainImage',
      'bannerImage',
      'centerImage',
      'centerLogo',
      'leftLogo',
      'onlineApplicationUrl',
      'onlineApplicationLinkText',
      'fileAttachment',
      'footerContent',
      'type',
      'created',
      'updated',
      '__v',
      '_id',
      'ownerId',
      'templateName',
      'pageName',
      'label',
      'yardiGuestCardId',
      'realPageGuestCardId',
      'knockProspectId',
      'entrataGuestCardId',
      'entrataApplicationId',
      'entrataApplicantId',
      'yardiTenantId',
      'realPageTenantId',
      'entrataTenantId',
      'resmanPersonId',
      'resmanLeaseId',
      'mainText',
      'headerText',
    ];
  }

  exitModal(e, modal) {
    e.preventDefault();
    modal.dismiss();
  }

  getTokensArray(controlValue: string, tokensToAppend: Array<string>): Array<any> {
    return this.templateTokens.get(controlValue).concat(tokensToAppend) || [];
  }

  getTemplateTokens() {
    const tokensMap = new Map();
    this.templates.forEach(template => {
      const templateTokens = [];
      template.groups.forEach(group => {
        group.fields?.forEach(field => {
          templateTokens.push(field.name);
        });
      });
      tokensMap.set(template._id, templateTokens);
    });

    return tokensMap;
  }

  displayPossibleSaveErrors(err) {
    const unwrapError = (errObj) => {
      if (!errObj) {
        return;
      }

      if (typeof errObj === 'string') {
        this.saveErrorMessages = [...this.saveErrorMessages, errObj];
        return;
      }

      if (Array.isArray(errObj)) {
        errObj.forEach(unwrapError);
        return;
      }

      if (typeof errObj !== 'object') {
        return;
      }

      for (const propName of Object.keys(errObj)) {
        if (!errObj[propName]) {
          continue;
        }
  
        unwrapError(errObj[propName]);
      }
    }

    unwrapError(err);

    this.showUserError('There was an error saving your automation');
  }

  showUserSuccess(message) {
    this.toastService.show(message, {
      classname: 'bg-success text-light',
      delay: 5000
    });
  }

  showUserError(message) {
    this.toastService.show(message, {
      classname: 'bg-danger text-light',
      delay: 5000
    });
  }
}