import { Component, ViewEncapsulation, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NurtureBossModalService } from '@app/_services/modal.service';
import { AuthenticationService, UserAuditsService } from '@app/_services';
import { UsersService } from '@app/_services/users.service';
import { ToastService } from '@app/_services';
import { SettingsService, SettingType } from '@app/_services/settings.service';
import MODAL_NAMES from '@app/_components/nb-modal/modalTypes';
import { IPrimaryButtonOptions } from '@app/_components/nb-modal/nb-modal.component';
import { FormGroup, FormControl } from '@angular/forms';
import { TemplateDefault } from '@nurtureboss/common/dist/types/templateDefaults';

@Component({ 
  selector: 'app-save-defaults-modal', 
  templateUrl: 'save-defaults-modal.component.html', 
  styleUrls: ['save-defaults-modal.component.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbActiveModal],
})
export class SaveDefaultsModalComponent implements OnInit {
  @Input() globalDefaults: TemplateDefault;

  saveDefaultsModalPrimaryButton: IPrimaryButtonOptions;
  loading = false;
  form: FormGroup;
  name: string = MODAL_NAMES.SAVE_DEFAULTS;

  constructor(
    private nbModalService: NurtureBossModalService,
    private usersService: UsersService,
    private toastService: ToastService,
    private userAuditsService: UserAuditsService,
    private settingsService: SettingsService,
  ) {
    this.onValueChange = this.onValueChange.bind(this);
    this.saveDefaultsModalPrimaryButton = {
      label: 'Update Defaults',
      disabled: false,
      action: this.buildUpdateDefaults(),
      showSpinner: false,
    };
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      defaults: new FormControl(true),
      blasts: new FormControl(false),
      automations: new FormControl(false),
    });

    for (const control in this.form.controls) {
      if (this.form.controls[control]) {
        this.form.controls[control].valueChanges.subscribe(this.onValueChange);
      }
    }
  }

  onValueChange(): void {
    let hasValue = false;
    for (const control in this.form.controls){
      if (this.form.controls[control].value) {
        hasValue = true;
        break;
      }
    }
    this.saveDefaultsModalPrimaryButton.disabled = !hasValue;
  }

  buildUpdateDefaults(): () => void {
    return () => this.updateDefaults();
  }

  updateDefaults(): void {
    this.saveDefaultsModalPrimaryButton.disabled = true;
    this.saveDefaultsModalPrimaryButton.showSpinner = true;

    const defaultOptions = {
      defaults: this.form.controls.defaults.value,
      blasts: this.form.controls.blasts.value,
      automations: this.form.controls.automations.value
    };
    this.usersService.setTemplateSelectedDefaults({defaultOptions, globalDefaults: this.globalDefaults.value}).subscribe(() => {
      
      // Update concession in Bot FAQ
      if (defaultOptions.defaults) {
        this.settingsService.saveSettingsByType(SettingType.Bot, {
          faq: {
            concessions: {
              content: this.globalDefaults.value.concession
            }
          },
        }).subscribe();
      }

      this.userAuditsService.refreshTriggeredUserAudits();
      this.nbModalService.close(this.name);
      this.toastService.showSuccess('Your global defaults have been updated!');
      this.saveDefaultsModalPrimaryButton.disabled = false;
      this.saveDefaultsModalPrimaryButton.showSpinner = false;
    }, (err) => {
      this.toastService.showError('There was an error setting your global defaults: ' + err);
      this.saveDefaultsModalPrimaryButton.disabled = false;
      this.saveDefaultsModalPrimaryButton.showSpinner = false;
    });
  }

  // TODO: make this not required
  buildSaveDefaultsModalDismissed(): (event: Event) => void {
    return () => {};
  }
}